import Vue from 'vue'
import axios from "axios";
const config =require('../config')

const state = {
    loggingIn: false,
    loggedIn: false,
    userDataRequested: false,
    token: "",
    displayName : "",
    userName: "",
    email: "",
    jwt: "",
    isLti: false,
    isLtiTeacher: false,
    ltiDeeplink: false,
    hideProjects: false,
    lti: {},
    mergeToken: "",
    showAccessibilityFeatures: false
};

const actions = {
    setTokens({ commit },{ token, jwt }) {
        if (jwt) {
            let d = new Date();
            d.setTime(d.getTime() + config.jwt.refreshRate*2);
            let ss;
            if (location.protocol === 'https:') {
                // allow embedding as iframe on Chrome
                ss='SameSite=None;Secure;';
            } else {
                ss='SameSite=Strict;';
            }
            document.cookie = config.jwt.cookieName + "=" + jwt + ";path=/;" + ss + "expires="+d.toUTCString();
        }
        if (token) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + token;
            try {
                sessionStorage.setItem('user_token', token);
                localStorage.removeItem('user_token');
            } catch (e) {
                console.log(e);
            }
        }
        commit('updateToken', { token, jwt });
    },
    clearTokens({ commit }) {
        document.cookie = config.jwt.cookieName + "='';SameSite=None;path=/;Secure;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        delete axios.defaults.headers.common['Authorization'];
        try {
            sessionStorage.removeItem('user_token');
            localStorage.removeItem('user_token');
        } catch (e) {
            console.log(e);
        }
        commit('updateToken', { token:"", jwt: "" });
    },
    async login({ dispatch, commit }, { username, password }) {
        commit('loginRequest');
        try {
            let res=await axios.post('/api/login', {username, password});
            let data = res.data;
            if ((res.status==200) && (data) && (data.token)) {
                commit('loginSuccess', {  displayName: data.realname, userName: data.username, email: data.email });
                dispatch('setTokens', { token: data.token, jwt: data.jwt });
                await window.$router.push('/projects');
            } else {
                // error message is displayed by the axois inteceptor
                commit('loginFailure');
            }
        } catch(error) {
            console.log(error);
            commit('loginFailure');
            dispatch('alert/error', error, {root: true});
        }
    },
    async changePassword({ dispatch }, { oldPw, newPw, confirmPw  }) {
        try {
            let res = await axios.post('/api/user/changepassword', { old: oldPw, new: newPw, confirm: confirmPw});
            if (res.data.error) {
                dispatch('alert/error', res.data.error, { root: true });
            } else {
                dispatch('alert/success', res.data.msg, { root: true });
            }
        } catch(error) {
            // error.response.status Check status code
            console.log(error);
            dispatch('alert/error', error, { root: true });
        }
    },
    async changeUser({ commit, dispatch }, { displayName  }) {
        try {
            let res = await axios.put('/api/user/me/update', { realname: displayName });
            if (res.data.error) {
                dispatch('alert/error', res.data.error, { root: true });
            } else {
                dispatch('alert/success', "Userdata updated", { root: true });
                commit('updateUser',{ displayName });
            }
        } catch(error) {
            // error.response.status Check status code
            console.log(error);
            dispatch('alert/error', error, { root: true });
        }
    },
    async me({ commit,dispatch }) {
        commit('loginRequest');
        try {
            let res=await axios.post('/api/user/me')
            if (res.status==200) {
                let data= res.data;
                if (data && data.username) {
                    commit('updateUser', {
                        displayName: data.realname,
                        userName: data.username,
                        email: data.email,
                    });
                    dispatch('setTokens', { token: data.newtoken, jwt: data.jwt });
                    return data;
                }
            }
        } catch(e) {
            console.log(e);
        }
        commit('loginFailure');
        await dispatch('loginPage');
    },
    async logout({ commit,dispatch }) {
        await axios.get('/api/logout');
        axios.defaults.headers.common['Authorization'] = "";
        commit('logout');
        dispatch('clearTokens');
        await dispatch('loginPage');
    },
    async logoutAll({ commit,dispatch }) {
        await axios.get('/api/logoutall');
        axios.defaults.headers.common['Authorization'] = "";
        commit('logout');
        dispatch('clearTokens');
        await dispatch('loginPage');
    },
    async createMergeToken({ commit,dispatch }) {
        try {
            let res=await axios.post('/api/user/createmergetoken',{});
            if ((res) && (res.status==200)) {
                await commit('setMergeToken', res.data);
            }
        } catch(error) {
            console.log(error);
            dispatch('alert/error', error, { root: true });
        }
    },
    async mergeAccounts({ commit, dispatch }, { token  }) {
        try {
            let res = await axios.post('/api/user/mergeaccounts', { token });
            if (res.data.error) {
                dispatch('alert/error', res.data.error, { root: true });
            } else 
            if (res.data.msg) {
                dispatch('alert/success', res.data.msg, { root: true });
                if (res.data.username) {
                    commit('setUserName',{ userName: res.data.username });
                }
            }
        } catch(error) {
            // error.response.status Check status code
            console.log(error);
            dispatch('alert/error', error, { root: true });
        }
    },

    async loginPage() {
        if (window.$router.currentRoute.path!='/login') {
            await window.$router.push('/login');
        }
    },
/*    register({ dispatch, commit }, user) {
        commit('registerRequest', user);

        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    router.push('/login');
                    setTimeout(() => {
                        // display success message after route change completes
                        dispatch('alert/success', 'Registration successful', { root: true });
                    })
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
 */
    async unauthorizedRequest({ commit,dispatch }) {
        await commit('logout');
        await dispatch('loginPage');
        // next tick... otherwise the router would clear the message
        Vue.nextTick(function() {
           window.$vm.$store.dispatch('alert/error',"Authorization failed: Please log in")
        });
    },
    toggleAccessibilityFeatures() {
        state.showAccessibilityFeatures = !state.showAccessibilityFeatures;
    }
};

const mutations = {
    loginRequest(state) {
        state.loggingIn=true;
    },
    loginSuccess(state, { displayName, userName, email }) {
        state.loggingIn=false;
        state.loggedIn=true;
        state.displayName = displayName;
        state.userName = userName;
        state.email = email;
    },
    updateUser(state, { displayName, userName, email }) {
        state.loggingIn=false;
        state.userDataRequested=true;
        if (displayName) {
            state.displayName = displayName;
        }
        if (userName) {
            state.userName = userName;
        }
        if (email) {
            state.email = email;
        }
    },
    updateToken(state, { jwt, token }) {
        if (jwt) {
            state.jwt = jwt;
        }
        if (token) {
            state.token=token;
        }
    },
    // eslint-disable-next-line no-unused-vars
    loginFailure(state) {
        state.loggingIn=false;
        state.token="";
        state.loggedIn=false;
        state.displayName = "";
        state.userName = "";
        state.jwt = "";
        state.mergeToken = "";
    },
    logout(state) {
        state.token="";
        state.loggedIn=false;
        state.displayName = "";
        state.userName = "";
        state.jwt = "";
        state.mergeToken = "";
    },
    setLtiObject(state, { ltiObject }) {
        state.isLti = true;
        state.lti = ltiObject;
    },
    setMergeToken(state, { mergeToken }) {
        state.mergeToken = mergeToken.token;
    },
    setUserName(state, { userName }) {
        state.userName = userName;
    }
};

export const user = {
    namespaced: true,
    state,
    actions,
    mutations
};