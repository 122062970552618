import axios from "axios";

const state = {
    projects : [],
    busyDeletingProject: null,
    busyPublishingProject: null,
    busyUnpublishingProject: null
};

const actions = {
    async loadProjects({ commit }) {
        let uri = '/api/project/list';
        let response = await axios.get(uri);
        if (response.status==200) {
            commit('setProjects', response.data);
        }
    },
    async addProject({ commit }) {
        let uri = '/api/project/add';
        let response = await axios.post(uri,{ name: "(new)" });
        if (response.status==200) {
            let project = response.data;
            commit('addProject', project);
        }
        return response;
    },
    async saveProjectName({ dispatch }, project) {
        let nn=project.name;
        if ((nn!=null) && (nn!="")) {
            let uri = '/api/project/' + project._id + '/update';
            let response = await axios.put(uri, {name: nn});
            if (response.status == 200) {
                project.name = nn;
            }
            return response;
        }
    },
    async saveProjectCustomProperties({ dispatch }, project) {
        let cp=project.custom;
        let uri = '/api/project/' + project._id + '/update';
        let response = await axios.put(uri, {custom: cp});
        return response;
    },
    async deleteProject({ commit }, project) {
        commit('setBusyDeletingProject', {project: project, busy: true});
        try {
            let uri = '/api/project/' + project._id + '/delete';
            let response = await axios.delete(uri);
            if (response.status == 200) {
                let ind = state.projects.indexOf(project);
                if (ind >= 0) {
                    commit('deleteProject', ind);
                } else {
                    console.log("deleteProject: project not found");
                }
            }
        } finally {
            commit('setBusyDeletingProject', {project: project, busy: false});
        }
    },
    async cloneProject({ commit }, project) {
        let uri = '/api/project/' + project._id + '/clone';
        let response=await axios.post(uri,{});
        if (response.status == 200) {
            commit('addProject', response.data);
        }
        return response;
    },
    async publishProject({ commit }, project) {
        commit('setBusyPublishingProject', {project: project, busy: true});
        try {
            let uri = '/api/project/' + project._id + '/publish';
            let response = await axios.post(uri);
            if (response.status == 200) {
                let ind = state.projects.indexOf(project);
                if (ind >= 0) {
                    commit('updateProjectPublished', {index: ind, value: true});
                } else {
                    console.log("publishProject: project not found");
                }
            }
            return response;
        } finally {
            commit('setBusyPublishingProject', {project: project, busy: false});
        }
    },
    async submitGrade({ dispatch }, project) {
        const searchParams = new URLSearchParams(window.location.search);
        const ltik = searchParams.get('ltik');
        if (!ltik) {
            console.log("LTI Token missing!");
            return;
        }
        let uri = '/lti_grade';
        await axios.post(uri,{ ltik, project: project._id } );
    },
    async unpublishProject({ commit }, project) {
        commit('setBusyUnpublishingProject', {project: project, busy: true});
        try {
            let uri = '/api/project/' + project._id + '/unpublish';
            let response = await axios.post(uri);
            if (response.status == 200) {
                let ind = state.projects.indexOf(project);
                if (ind >= 0) {
                    commit('updateProjectPublished', { index: ind, value:false });
                } else {
                    console.log("unpublishProject: project not found");
                }
            }
            return response;
        } finally {
           commit('setBusyUnpublishingProject', {project: project, busy: false});
        }
    },
};

const mutations = {
    setProjects(state, projects) {
        state.projects = [];
        state.projects.push(...projects);
     },
    addProject(state, project) {
        state.projects.push(project);
      },
    deleteProject(state, index) {
        state.projects.splice(index, 1);
    },
    updateProject(state, { index, project }) {
        let oldProject = state.projects[index];
        Object.assign(oldProject, project);
    },
    updateProjectPublished(state, { index, value }) {
        state.projects[index].published = value;
    },
    setBusyDeletingProject(state, payload) {
        if (payload.busy) {
            state.busyDeletingProject = payload.project;
        } else {
            state.busyDeletingProject = null;
        }
    },
    setBusyPublishingProject(state, payload) {
        if (payload.busy) {
            state.busyPublishingProject = payload.project;
        } else {
            state.busyPublishingProject = null;
        }
    },
    setBusyUnpublishingProject(state, payload) {
        if (payload.busy) {
            state.busyUnpublishingProject = payload.project;
        } else {
            state.busyUnpublishingProject = null;
        }
    }
};

export const projects = {
    namespaced: true,
    state,
    actions,
    mutations
};