<template>
    <b-modal id="deeplink-modal" title="LTI Deeplink" ok-only ok-title="Close" centered>
        <b-button v-on:click="sendDeeplink('Clone ' + dlProjectName,`/lti?projectid=${dlProjectId}&clone=1`)">Students can edit a clone of this project</b-button>
        <br><br>
        <b-button v-on:click="sendDeeplink('Show ' + dlProjectName,`/lti?projectid=${dlProjectId}&show=1`)">Students see this project</b-button>
    </b-modal>
</template>

<script>

export default {
    name: 'DeeplinkModal',
    props: ['dlProjectName', 'dlProjectId'],
    methods: {
        sendDeeplink(title, url) {
            const Http = new XMLHttpRequest();
            const searchParams = new URLSearchParams(window.location.search);
            const ltik = searchParams.get('ltik');
            if (!ltik) {
                console.log("LTI Token missing!", title, url);
                return;
            }
            Http.open("POST", '/deeplink?ltik=' + ltik);
            Http.setRequestHeader('Content-Type', 'application/json');
            if (url.indexOf("://")===-1) {
                url=window.location.origin + url;
            }
            let req={
                title: title,
                path: url
            };
            Http.send(JSON.stringify(req));
            Http.onreadystatechange = function() {//Call a function when the state changes.
                if(Http.readyState == 4 && Http.status == 200) {
                let element = document.createElement('div');
                element.innerHTML=Http.responseText;
                document.body.appendChild(element);
                // send it later, to leave the handler...
                setTimeout(()=> {
                    document.getElementById("ltijs_submit").submit();
                });
                }
            }
        }
    }
}

</script>