import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";

let stored_token=window.ggUserToken;
try {
    if (!stored_token) stored_token = sessionStorage.getItem('user_token');
    if (!stored_token) stored_token = localStorage.getItem('user_token');
} catch(e) {
    console.log(e);
}

if (stored_token) {
    axios.defaults.headers.common['Authorization'] = "Bearer " + stored_token;
}

import { alert } from './alert.js';
import { user } from './user.js';
import { navigation } from './navigation.js';
import { projects } from './projects.js';
import { nodes } from './nodes.js';
import { hotspots } from './hotspots.js';
import { sounds } from './sounds.js';

if (stored_token) {
    user.state.loggedIn = true;
    user.state.token = stored_token;
}

let ltiObject = window.ggLtiObject;
let ltiConfig = window.ggLtiConfig;

try {
    if (!ltiObject) {
        ltiObject = JSON.parse(sessionStorage.getItem('lti_object'));
    }
} catch (e) {
    console.log(e);
}
if (ltiConfig) {
    user.state.isLti=true;
    user.state.lti = ltiObject;
    user.state.isLtiTeacher=!!ltiConfig.isTeacher;
    user.state.ltiDeeplink=!!ltiConfig.deeplink;
    user.state.hideProjects=!!ltiConfig.hideProjects;
}

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        user,
        alert,
        navigation,
        projects,
        nodes,
        hotspots,
        sounds
    }
});
