import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

export const PublishProjectMixin = {
  computed: {
      ...mapState('projects', ['busyPublishingProject', 'busyUnpublishingProject']),
  },
  methods: {
    ...mapActions('projects', ['publishProject', 'unpublishProject']),
    ...mapActions({ alertSuccess: 'alert/success' }),
    closeTooltips() {
      let me = this;
      Vue.nextTick(function () {
        me.$root.$emit('bv::hide::tooltip');
      });
    },
    async publishProjectWithFeedback(project) {
      this.closeTooltips();
      let response = await this.publishProject(project);
      if (response && response.status == 200) {
        this.alertSuccess('Project successfully shared.');
      }
      return response;
    },
    async unpublishProjectWithFeedback(project) {
      if (project.published) {
        this.closeTooltips();
        let response = await this.unpublishProject(project);
        if (response && response.status == 200) {
          this.alertSuccess('Project successfully unshared.');
        }
      }
    },
    async publishOk(project) {
      if (!project.published) {
        let value = await this.$bvModal.msgBoxConfirm('By clicking Confirm, you agree that this material does not violate any fair use or infringe on anyone else’s rights. ' +
            'This project will be published via a public link that allows anyone with this link to view this project and its contents. ' +
            'This tool is currently in beta, any location data on photos you upload is not protected.',
            {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: 'Confirm',
          cancelTitle: 'Back',
          footerClass: 'p-2',
          hideHeaderClose: false
        });
        if (value) {
          let response = await this.publishProjectWithFeedback(project);
          if (response.status != 200) {
            return false;
          }
        } else {
          return false;
        }
      }
      return true;
    },
    async shareProject(project) {
      if (await this.publishOk(project)) {
        this.shareUrl = project.publicUrl;
        this.$bvModal.show('share-modal');
      }
    },
  }
}