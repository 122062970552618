<template>
  <div id="app">
    <div>
      <div id="header" class="bg-secondary text-light pt-2 pb-1 pb-lg-2">
        <div class="container">
          <div class="row">
            <div class="col col-2" style="background-image: url('/assets/touritlogo.png'); background-size: contain; background-repeat: no-repeat" />
            <div class="col col-6 col-lg-7">
              <div v-if="loggedIn==true">
                <div class="float-left">
                  <nav class="mb-1 mt-0">
                    <b-breadcrumb style="margin-block-end: 0px !important">
                      <li v-if="(!hideProjects)" class="breadcrumb-item"><router-link to="/projects" class="text-dark" tabindex="-1"><b-button class="px-2 py-1 button-responsive" :disabled="(!currentProject._id&&$route.path!='/user')" tabindex="1"><b-icon icon="briefcase-fill" class="mr-1"></b-icon>Projects</b-button></router-link></li>
                      <li v-if="$route.path=='/user'" class="breadcrumb-item"><b-button class="px-2 py-1" disabled tabindex="-1"><b-icon icon="person-circle" class="mr-1"></b-icon>User</b-button></li>
                      <li v-if="Object.keys(currentProject).length > 0 && $route.path!='/user'" class="breadcrumb-item"><router-link :to="{path: '/nodes', query: {projectId: currentProject._id}}" class="text-dark" tabindex="-1"><b-button class="px-2 py-1" :disabled=!currentNode._id tabindex="2"><b-icon icon="image" class="mr-1"></b-icon>Panoramas</b-button></router-link></li>
                      <li v-if="Object.keys(currentNode).length > 0 && $route.path!='/user'" class="breadcrumb-item"><b-button class="px-2 py-1" disabled tabindex="-1"><b-icon icon="pencil-fill" class="mr-1"></b-icon>Editor</b-button></li>
                    </b-breadcrumb>
                  </nav>
                </div>
              </div>
            </div>
            <div class="col col-4 col-lg-3">
              <div v-if="loggedIn==true">
                <div class="mt-1 mt-lg-2">
                  <b-button v-if="!isLti" class="button-responsive" variant="outline-light float-right ml-2 mb-2" tabindex="4" @click="logout" v-b-tooltip.ds200.dh0.hover title="Logout"><b-icon icon="power"></b-icon></b-button>
                  <b-button class="button-responsive" variant="outline-light float-right ml-2" tabindex="3" @click="$router.push('/user').catch(()=>{});" v-b-tooltip.ds200.dh0.hover :title="'User: ' + displayName"><b-icon icon="person-circle"></b-icon></b-button>
                  <b-button class="button-responsive" variant="outline-light float-right" tabindex="2" v-b-tooltip.ds200.dh0.hover title="Accessibility" v-b-modal.modal-accessibility><font-awesome-icon icon="universal-access" size="lg" /></b-button>
                </div>
              </div>
              <div v-else>
                <b-button  variant="outline-light float-right mt-2" @click="loginPage"><b-icon icon="power"></b-icon> Login</b-button>
              </div>
            </div>
          </div> <!-- container 1st row -->
        </div> <!-- bootstrap container -->
        <b-modal id="modal-accessibility" size="lg" header-bg-variant="light" footer-bg-variant="light" cancel-disabled title="Accessibility">
          <p>When the accessibility features are turned on, the item that is currently active on the page will show a black border.</p>
          <p>In the Editor view, an additional ‘joystick button’ (<b-icon icon="joystick"></b-icon>) will now appear on the button bar in the upper right portion of the screen.<br>The ‘joystick button’ allows movement of the panorama by using the arrow keys on the keyboard. Using the ‘Shift’ and ‘Ctrl’ keys will cause the panorama to zoom in and out.<br>The joystick button will become active by pressing ‘Tab’ or by pressing the ‘Alt’ key while in the Editor view.<br>Once done moving the panorama with the arrow keys, press the ‘Alt’ key again to return to the previous active element.</p>
          <p>To turn the accessibility features on, either press the ‘Tab’ key anywhere in the page, or use the button below.</p>
          <b-button variant="primary" class="ml-2" @click="toggleAccessibility"><span v-if="showAccessibilityFeatures">Turn Accessibility Off</span><span v-else>Turn Accessibility On</span></b-button>
        </b-modal>
      </div>
      <!-- route outlet -->
      <!-- component matched by the route will render here -->
      <keep-alive include="PanoEditor">
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { HelperFunctionsMixin } from "./mixins/helperFunctionsMixin"

const config =require('./config')

export default {
  name: 'app',
  mixins: [HelperFunctionsMixin],
  components: { FontAwesomeIcon },
  data() {
    return{
      pageTitle: "Tour It",
    }
  },
  computed: {
    ...mapState('projects', ['projects']),
    ...mapState('nodes', ['nodes']),
    ...mapState('user', ['loggedIn','displayName','token','isLti','lti','isLtiTeacher','hideProjects','showAccessibilityFeatures']),
    ...mapState('navigation', ['currentProject','currentNode']),
    userName() {
      console.log(this.user);
      return this.user.loggedIn?this.user.displayName:"(not logged in)";
    }
  },
  methods: {
    ...mapActions({
      loadProjects: 'projects/loadProjects',
      loadNodes: 'nodes/loadNodes',
      setCurrentProject: 'navigation/setCurrentProject',
      setCurrentNode: 'navigation/setCurrentNode',
      logout: 'user/logout',
      loginPage: 'user/loginPage',
      fetchUserMe: 'user/me',
      toggleAccessibilityFeatures: 'user/toggleAccessibilityFeatures',
      alertSuccess: 'alert/success',
      alertInformation: 'alert/information',
      alertError: 'alert/error',
    }),
    async loadDataFromUrlParams() {
      let me = this;
      let projectId = window.ggOpenProjectId;
      try {
        let queryProjectId;
        if (projectId) {
          queryProjectId = projectId;
        }
        if ((me.$route.query.projectId) && (me.$route.query.projectId!='')) {
          queryProjectId = me.$route.query.projectId;
        }
        let queryNodeId = me.$route.query.nodeId;
        //     sessionStorage.setItem('projectid','5fe0c479d92a3a130445b331');
        if ((queryNodeId && queryNodeId != '') || (queryProjectId && queryProjectId != '')) {
          await me.loadProjects();
        }
        if (queryNodeId && queryNodeId != '') {
          let uri = '/api/node/' + queryNodeId + '/get';
          let response=await axios.get(uri);
          if (response.status == 200) {
            let node = response.data;
            let queryProject = me.projects.find(element => element._id == node.project);
            await me.setCurrentProject(queryProject);
            let queryNode = me.nodes.find(element => element._id == queryNodeId);
              me.setCurrentNode(queryNode);
            }
        } else
        if (queryProjectId && queryProjectId != '') {
          let queryProject = me.projects.find(element => element._id == queryProjectId);
          me.setCurrentProject(queryProject);
        }
      } catch (e) {
        console.log(e);
      }
      if ((Object.keys(me.currentProject).length == 0) && (me.$route.path != '/projects')) {
        await me.$router.replace('/projects');
        return;
      }
      if ((Object.keys(me.currentProject).length > 0) && (Object.keys(me.currentNode).length == 0) && (me.$route.path != '/nodes')) {
        await me.$router.replace({path: '/nodes', query: me.$route.query});
        return;
      }
    },
    turnOnAccessibility(event) { // globally, with tab key
      if (!this.showAccessibilityFeatures && event.key === 'Tab') {
        let node = document.activeElement.parentNode;
        while (node) {
          if (node.tagName == 'FORM') return; // do not turn on accessibility if active element is inside a form
          node = node.parentNode;
        }
        this.toggleAccessibility();
      }
    },
    toggleAccessibility() {
      this.toggleAccessibilityFeatures();
      if (this.showAccessibilityFeatures) {
        document.styleSheets[0].insertRule(':focus {box-shadow: inset 0px 0px 1px 4px #000000 !important}', 0);
        document.styleSheets[0].insertRule('img:focus {box-shadow: 0px 0px 1px 5px #000000 !important}', 0);
        document.styleSheets[0].insertRule('span:focus {box-shadow: 0px 0px 2px 0px #000000 !important; border: 4px solid black}', 0);
        document.styleSheets[0].insertRule('button.page-link:focus {box-shadow: 0px 0px 1px 4px #000000 !important}', 0);
        this.alertInformation('Accessibility features on.');
      } else {
        document.styleSheets[0].deleteRule(0);
        document.styleSheets[0].deleteRule(0);
        document.styleSheets[0].deleteRule(0);
        document.styleSheets[0].deleteRule(0);
        this.alertInformation('Accessibility features off.');
      }
    }
  },
  created: function() {
    this.fetchUserMe();
    // update jwt cookie!
    setInterval(() => {
      if (this.loggedIn) {
        this.fetchUserMe();
      }
    },config.jwt.refreshRate);
    document.title = this.pageTitle;
    if (this.loggedIn) {
      this.loadDataFromUrlParams();
    }
    document.addEventListener('keyup', this.turnOnAccessibility);
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route (to, from) {
      // clear alert on location change
      document.title = to.meta.title || this.pageTitle;
    }
  }
};

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  --asu-color: #901340;
  --asu-lighter-color: #a52954;
  --asu-accent-color: lightgray;
  --asu-bg-gray: #e9ecef;
}
.breadcrumb {
  padding: 0.75rem !important;
}
.breadcrumb-item + .breadcrumb-item::before {
  padding-top: 0.25em;
  content: "-" !important;
}
.btn.btn-secondary[disabled] {
    background-color: var(--asu-lighter-color);
    opacity: 1.0;
    border: 1px solid var(--asu-color);
}
.pagination .page-link {
  color: var(--asu-color)!important;
}
.pagination .page-item.active .page-link{
  background-color: var(--asu-color)!important;
  border-color: var(--asu-color)!important;
  color: var(--asu-accent-color)!important;
}
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}
.table-thumbnail {
  width: 256px;
  height: 128px; 
}
.table-thumbnail-icon {
    min-height: 128px;
}
@media all and (max-width: 1200px) {
  .table-thumbnail {
    width: 128px;
    height: 64px;
    font-size: 0;
  }
  .table-thumbnail-icon {
    min-height: 64px;
  }
  .table-thumbnail-text {
    display: none;
  }
}
@media all and (max-width: 992px) {
  .button-responsive {
    padding: 0.25rem 0.5rem !important;
  }
  .dropdown-responsive {
    max-height: 38px !important;
  }
  .breadcrumb {
    padding: 0.25rem !important;
  }
  .alert-responsive {
    padding: 0.5rem 4rem 0.5rem 0.5rem !important;
  }
  .h1 {
    font-size: 30px !important;
    line-height: 38px !important;
    margin-top: 3px;
  }
}
@media all and (max-width: 768px) {
  .h1 {
    font-size: 22px !important;
    line-height: 28px !important;
    margin-top: 7px;
  }
  .table-thumbnail {
    width: 64px;
    height: 32px;
    font-size: 0;
  }
  .table-thumbnail-icon {
    min-height: 32px;
  }
}
@media all and (min-width: 1201px) {
  .tooltip-responsive {
    visibility: hidden;
  }
}
</style>
