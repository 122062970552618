<template>
  <div>
    <h1>Hotspots</h1>
    <div>Current Project/Node: {{ currentProject?currentProject.name:"-" }}, {{ currentNode.userdata?currentNode.userdata.title:"-" }}</div>
    <table class="table table-hover">
      <thead>
      <tr>
        <th v-on:click="loadHotspots">ID---</th>
        <th>Title</th>
        <th>Url</th>
        <th>Delete</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="hotspot in hotspots" :key="hotspot._id">
        <td>{{ hotspot._id }} - {{ hotspot.hotspotId }}</td>
        <td><span v-if="hotspot==editHotspotTitle"><input v-on:blur="saveTitle(hotspot)" v-on:keyup.enter="saveTitle(hotspot)" ref="name" type="text" v-model="hotspot.title" autofocus></span>
          <span v-on:click="setEditTitle(hotspot)" v-else>{{hotspot.title}} ---</span>
        </td>
        <td>{{ hotspot.url }}</td>
        <td v-on:click="confirmDeleteHotspot(hotspot)"><b-icon icon="trash-fill"/></td>
      </tr>
      </tbody>
    </table>
    <div v-on:click="addEmptyHotspot()">Add Hotspot</div>
  </div>
</template>

<script>

import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Hotspots',
  data(){
    return {
      editHotspotTitle: null,
    }
  },  computed: {
    ...mapState('navigation', ['currentProject','currentNode']),
    ...mapState('hotspots', ['hotspots'])
  },
  created: function()
  {
  },
  methods: {
    ...mapActions('navigation', ['setCurrentProject', 'setCurrentNode']),
    ...mapActions('hotspots', ['loadHotspots', 'addHotspot', 'saveHotspot', 'deleteHotspot']),
     setEditTitle(hs) {
      this.editHotspotTitle = hs;
      let me = this;
      Vue.nextTick(function () {
        me.$refs.name[0].focus();
      })
    },
    confirmDeleteHotspot(hs) {
      this.$bvModal.msgBoxConfirm('Are you sure?')
        .then(value => {
          if (value) {
            this.deleteHotspot(hs);
            }
        });
    },
    saveTitle(hs) {
      let nn = hs.title;
      // nn = prompt("new name", project.name);
      if ((nn != null) && (nn != "")) {
        this.saveHotspot(hs);
      }
      this.editHotspotTitle = null;
    },
    addEmptyHotspot() {
      this.addHotspot({});
    }
  }
}
</script>

<style scoped>
h1 {
  margin: 40px 0 0;
}
</style>
