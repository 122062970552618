<template>
  <div>
    <form v-on:submit.prevent="submitForm">
      {{error}}
      <table class="mx-auto mt-3">
        <tr>
          <td><label for="username">Name</label></td>
          <td><input type="text" autocomplete="no" class="form-control" id="username" placeholder="username" v-model="form.username"></td>
        </tr>
        <tr>
          <td><label for="password">Password</label></td>
          <td><input type="password" autocomplete="no" class="form-control" id="password" placeholder="password" v-model="form.password"></td>
        </tr>
        <tr>
          <td></td>
          <td>
            <b-button variant="outline-secondary" type="submit" class="float-left my-2">Submit</b-button>
          </td>
        </tr>
      </table>
    </form>
    <!-- <a href="/auth/google">Sign In with Google</a> <br>
    <a href="/auth/facebook">Sign In with Facebook</a> -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Login',
  data(){
    return {
      error:'',
      form: {
        username: '',
        password: '',
      }
    }
  },
  computed: {
    ...mapState('user', ['user'])
  },
  methods: {
    ...mapActions('user', ['login', 'logout']),
    submitForm(){
      this.login({ username: this.form.username, password: this.form.password });
    }
  }
}

</script>

<style scoped>
h1 {
  margin: 40px 0 0;
}
</style>
