export const HelperFunctionsMixin = {
    methods: {
        confirmOpenPreview(project, nodeId) {
            this.$bvModal.msgBoxConfirm('Project will be opened in preview mode in a seperate tab.')
                .then(value => {
                    if (value) {
                        var tab;
                        if (nodeId) {
                            tab = window.open('/api/project/' + project._id + '/index.html?currentNode=' + nodeId + '&_jwt=' + this.jwt + '&ts=' + (new Date().getTime() % 10000000), 'tourit_preview');
                        } else {
                            tab = window.open('/api/project/' + project._id + '/index.html?_jwt=' + this.jwt+ '&ts=' + (new Date().getTime() % 10000000), 'tourit_preview');
                        }
                        tab.focus();
                    }
                });
        },
    }
}