<template>
   <b-modal id="share-modal" title="Share Project" header-bg-variant="light" hide-footer>
       <form>
            <label for="url" class="form-control-label">Project Link:</label>
            <b-input-group>
                <input class="form-control" id="url" disabled type="text" v-model="projectLink">
                <b-input-group-append>
                    <b-button variant="outline-secondary" @click="copyLinkToClipboard" v-b-tooltip.ds200.dh0.hover title="Copy to clipboard."><b-icon icon="files"></b-icon></b-button>
                    <b-button variant="outline-secondary" @click="openLinkInTab" v-b-tooltip.ds200.dh0.hover title="Open in new tab."><b-icon icon="globe2"></b-icon></b-button>
                </b-input-group-append>
            </b-input-group>
       </form>
   </b-modal>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'ShareModal',
    data() {
        return {
        }
    },
    props: ['publicUrl'],
    computed: {
        projectLink: function() {
            return this.publicUrl + "/index.html";
        }
    },
    methods: {
    ...mapActions({ alertSuccess: 'alert/success', alertInformation: 'alert/information' , alertError: 'alert/error' }),
        copyLinkToClipboard() {
            var me = this;
            this.$bvModal.hide('share-modal');
            if (!navigator.clipboard) {
                let urlEdit = document.getElementById("url");
                if (urlEdit) {
                    urlEdit.focus();
                    urlEdit.select();
                    urlEdit.setSelectionRange(0, 99999);
                    if (document.execCommand('copy')) {
                        this.alertInformation("Project link copied to clipboard.");
                    } else {
                        this.alertError("Problem copying project link!");
                    }
                }
            } else {
                navigator.clipboard.writeText(this.projectLink).then(function() {
                    me.alertInformation("Project link copied to clipboard.");
                }, function(err) {
                    me.alertError("Problem copying project link: " + err);
                });
            }
        },
        openLinkInTab() {
            this.$bvModal.hide('share-modal');
            var tab = window.open(this.projectLink + "?ts=" + (new Date().getTime() % 10000000), 'tourit_public');
            tab.focus();
       }
    }
}
</script>