<template>
  <div>
    <div class="container-lg bg-light px-0 py-2">
      <div class="px-2 clearfix">
        <span v-if="editProjectName" class="float-left"><input class="h1" v-on:blur="saveProjectNameAndStopEditing()"
            v-on:keyup.enter="saveProjectNameAndStopEditing()" ref="projectName" type="text" v-model="currentProject.name"
            autofocus></span>
        <span v-else class="h1 float-left" style="cursor: pointer" @click="setEditProjectName()"
          @keyup.enter="setEditProjectName()" tabindex="0">{{ currentProject.name }} <b-icon class="h4" icon="pencil-fill"
            title="Edit Project Name" /></span>
        <a v-if="nodes.length > 0" class="btn btn-outline-secondary button-responsive float-left ml-3 mt-1" role="button"
          @click="confirmOpenPreview(currentProject)" v-b-tooltip.ds200.dh0.hover title="Preview"
          @keyup.enter="confirmOpenPreview" tabindex="0"><b-icon icon="binoculars-fill" /></a>
        <b-spinner v-if="(busyPublishingProject != null) || (busyUnpublishingProject != null)"></b-spinner>
        <span v-else>
          <b-button v-if="nodes.length > 0" variant="outline-secondary button-responsive" class="float-left ml-3 mt-1"
            @click="shareProject(currentProject)" v-b-tooltip.ds200.dh0.hover title="Share"><b-icon
              icon="share-fill"></b-icon></b-button>
          <b-button v-if="nodes.length > 0 && currentProject.published" variant="outline-secondary button-responsive"
            class="float-left ml-1 mt-1" :disabled="busyUnpublishingProject != null"
            @click="unpublishProjectWithFeedback(currentProject)" v-b-tooltip.ds200.dh0.hover title="Make Private"><b-icon
              icon="eye-slash-fill"></b-icon></b-button>
          <b-button v-if="nodes.length > 0 && currentProject.published" variant="outline-secondary button-responsive"
            class="float-left ml-1 mt-1" :disabled="busyPublishingProject != null"
            @click="publishProjectWithFeedback(currentProject)" v-b-tooltip.ds200.dh0.hover
            title="Update Changes to Public View"><b-icon icon="arrow-repeat"></b-icon></b-button>
          <b-button v-if="nodes.length > 0 && ltiDeeplink" variant="outline-secondary button-responsive"
            class="float-left ml-3 mt-1" @click="saveReturnToLTI" v-b-tooltip.ds200.dh0.hover
            title="Save and return to LMS."><b-icon icon="cloud-upload-fill"></b-icon></b-button>
          <b-button variant="outline-secondary button-responsive" class="float-left ml-3 mt-1"
            v-b-toggle.projectSettingsCollapse v-b-tooltip.ds200.dh0.hover title="Project Settings"><b-icon
              icon="toggles"></b-icon></b-button>
        </span>
        <div class="float-right clearfix" style="height: 50px">
          <b-spinner v-if="busyLoading" label="Loading..."></b-spinner>
          <b-pagination v-if="nodes.length > perPage && !busyLoading" v-model="currentPage" :total-rows="rows"
            :per-page="perPage" align="center" aria-controls="nodes-table" class="mt-1" @change="pageChanged">
          </b-pagination>
        </div>
      </div>
      <b-collapse v-if="currentProject" id="projectSettingsCollapse" class="card rounded-0">
        <div class="card-header">
          <span class="h6 font-weight-bold float-left">Project Settings</span>
        </div>
        <div class="card-body">
          <form id="projectSettingsForm" class="text-left">
            <div class="form-group">
              <label class="form-control-label" for="welcome-message">Welcome Message</label>
              <textarea class="form-control" name="welcome-message" id="welcome-message" v-model="welcomeMessage" rows="5"
                placeholder="Enter welcome message..." @blur="saveWelcomeMessageAndCredits"></textarea>
            </div>
            <div class="form-group">
              <label class="form-control-label" for="credits">Credits / Additional Information</label>
              <textarea class="form-control" name="credits" id="credits" v-model="credits" rows="5"
                placeholder="Enter credits..." @blur="saveWelcomeMessageAndCredits"></textarea>
            </div>
            <b-form-checkbox id="disableThumbnails" v-model="disableThumbs">Disable Thumbnail Menu</b-form-checkbox>
            <b-form-checkbox id="disableWelcomeScreen" v-model="disableWelcome">Disable Welcome Screen</b-form-checkbox>
            <b-form-checkbox id="disableNodeTitle" v-model="disableNodeTitle">Disable Panorama Title</b-form-checkbox>
            <b-form-checkbox id="disableHotspotTitls" v-model="disableHotspotTitle">Disable Hotspot Title</b-form-checkbox>
          </form>
        </div>
      </b-collapse>
      <table id="nodes-table" v-if="nodes.length > 0" class="table table-hover">
        <thead>
          <tr>
            <th style="width:5%" id="pageUpTarget" @dragenter="pageUpTargetHover" @dragleave="pageUpTargetHover"
              @dragover.prevent @drop="pageUpTargetDropped"><b-icon v-show="showPageUpTarget" icon="chevron-up"
                animation="throb" class="dropTarget" @dragenter.prevent @dragover.prevent></b-icon></th>
            <th v-on:click="loadNodes" style="width:40%">Panorama</th>
            <th style="width:30%">Title</th>
            <th style="width:20%"></th>
          </tr>
        </thead>
        <draggable tag="tbody" handle=".handle" draggable=".dragItem" @start="nodesDragStart" @end="nodesTableChanged">
          <tr v-for="node in nodesForPage" :key="node._id" class="dragItem">
            <td>
              <b-icon class="handle" style="cursor: grab" icon="grip-horizontal" title="Drag to Rearrange" />
            </td>
            <td>
              <span v-if="node.status == 'ready'" style="cursor: pointer"
                @click="setCurrentNode(node); $router.push({ path: '/panoeditor', query: { nodeId: node._id } });"
                title="Edit Panorama">
                <img class="table-thumbnail"
                  :src="'/s3/preview/' + node.image.folder + '/' + node.nodeId + '/preview.jpg?_jwt=' + jwt + '&v=' + node.__v"
                  @keyup.enter="setCurrentNode(node); $router.push({ path: '/panoeditor', query: { nodeId: node._id } });"
                  tabindex="0" :alt="'Thumbnail Image of Panorama ' + node.userdata.title">
                <b-icon variant="light" class="ml-n4 mt-2"
                  style="vertical-align: top; -webkit-filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, 1.0)); filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, 1.0));"
                  icon="pencil-fill"></b-icon>
              </span>
              <span v-else>
                <b-spinner /> processing ({{ node.status }})...
              </span>
            </td>
            <td><span v-if="node == editNodeTitle"><input @blur="saveTitle(node)" @keyup.enter="saveTitle(node)" ref="name"
                  type="text" v-model="node.userdata.title" autofocus></span>
              <span @click="setEditTitle(node)" v-else style="cursor: pointer;" @keyup.enter="setEditTitle(node)"
                tabindex="0">{{ node.userdata.title }} <b-icon icon="pencil-fill" title="Edit Panorama Title" /></span>
            </td>
            <td>
              <input type="file" name="file" :id="'file_' + node.nodeId" style="display: none"
                @change="replaceNodeInput(node)" accept=".jpg,.jpeg,imager/jpeg" />
              <b-dropdown variant="outline-dark" size="sm" no-flip>
                <template #button-content>
                  <span>
                    <b-icon icon='three-dots-vertical'></b-icon> Actions
                  </span>
                </template>
                <b-dropdown-item-button variant="dark" class="btn-sm"
                  @click="setCurrentNode(node); $router.push({ path: '/panoeditor', query: { nodeId: node._id } });">
                  <b-icon icon="pencil-fill"></b-icon> Edit
                </b-dropdown-item-button>
                <b-dropdown-item-button variant="dark" class="btn-sm" @click="selectReplaceNodeInput(node)">
                  <b-icon icon="file-earmark-image"></b-icon> Replace Input
                </b-dropdown-item-button>
                <b-dropdown-item-button v-if="node != nodes.at(0)" variant="dark" class="btn-sm"
                  @click="moveNodeUpDown(node, true)">
                  <b-icon icon="arrow-up"></b-icon> Move Up
                </b-dropdown-item-button>
                <b-dropdown-item-button v-if="node != nodes.at(nodes.length - 1)" variant="dark" class="btn-sm"
                  @click="moveNodeUpDown(node, false)">
                  <b-icon icon="arrow-down"></b-icon> Move Down
                </b-dropdown-item-button>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item-button variant="warning" class="btn-sm" @click="confirmDeleteNode(node)">
                  <b-icon icon="trash-fill"></b-icon> Delete
                </b-dropdown-item-button>
              </b-dropdown>
            </td>
          </tr>
        </draggable>
        <td v-show="showPageDownTarget" id="pageDownTarget" style="height: 25px" colspan="4"
          @dragenter="pageDownTargetHover" @dragleave="pageDownTargetHover" @dragover.prevent
          @drop="pageDownTargetDropped">
          <b-icon icon="chevron-down" animation="throb" class="dropTarget float-left ml-2" @dragenter.prevent
            @dragover.prevent></b-icon>
        </td>
      </table>
      <vue-dropzone :key="currentProject._id" class="rounded m-3" ref="myVueDropzoneNodes" id="dropzoneNodes"
        :options="dropzoneOptions" :useCustomSlot=true v-on:vdropzone-complete="dropzoneComplete"
        v-on:vdropzone-error="dropzoneError">
        <div class="dropzone-custom-content" @keyup.enter="openPanoramaFileBrowser" tabindex="0">
          <svg width="1272" height="568" viewBox="0 0 1272 568" class="d-inline"
            style="width:130px; height: 130px; vertical-align: middle">
            <!-- use inline svg, so we can change fill color with css -->
            <path d="M30.4,549.4c0-177.3,0-354,0-531.3c12.9,3.7,25.4,8,38.1,10.8C103.2,36.7,138,44.8,173,50.9C214.2,58,255.6,64,297.1,68.7
              c45.7,5.2,91.5,9,137.4,12.2c86.5,6,173.1,7.8,259.8,5.7c52.6-1.3,105.2-3.6,157.8-6.8c38.9-2.4,77.7-6.6,116.4-10.6
              c52.7-5.4,105-13.3,157.1-23.1c37.8-7.1,75.3-15.3,112-26.9c1.2-0.4,2.6-0.6,4.1-0.9c0,177,0,353.7,0,531.1
              c-6-1.5-11.8-2.8-17.5-4.3c-49.2-12.2-98.9-21.5-148.9-28.9c-44.8-6.6-89.7-12.8-134.7-17.7c-41.2-4.5-82.6-7.6-124-10.5
              c-76.2-5.4-152.5-7.4-228.8-5.5c-54.4,1.4-108.9,3.7-163.2,7.7c-52.5,3.8-104.8,9.4-157,15.8c-78.8,9.6-157.1,22.8-234.1,42.8
              C32.6,549.2,31.8,549.2,30.4,549.4z M476,381.9c-13,0-25.3,0-38.4,0c1.3,2.3,2,3.7,2.9,5.1c9,14.2,18,28.4,26.9,42.7
              c1.7,2.8,3.6,3.7,6.8,3.5c18.1-1,36.3-2,54.4-2.7c3.3-0.1,5-1.3,6.7-4c13-20.7,26.1-41.4,39.2-62.1c0.8-1.2,1.4-2.5,2.5-4.5
              c-15.6,0-30.5,0-46.3,0c34.1-58.5,67.7-116.1,101.7-174.4c34.1,58.5,67.6,116,101.8,174.7c-17.6,0-33.8,0-50.3,0
              c0.4,1.1,0.5,1.6,0.8,2c13.8,21.8,27.5,43.7,41.5,65.4c0.8,1.3,3.1,2.4,4.8,2.5c19.6,1,39.3,1.8,58.9,2.4c1.8,0.1,4.4-1.4,5.4-2.9
              c6.1-9.1,11.9-18.5,17.7-27.8c4-6.3,7.9-12.6,12.5-19.8c-12.3,0-23.6,0-35.5,0c26-44.6,51.5-88.3,77.4-132.7
              c26.1,44.7,51.6,88.4,77.6,133.1c-13.6,0-25.8,0-38.2,0c0.4,1,0.5,1.5,0.7,1.9c12.3,19.4,24.5,38.9,36.9,58.2
              c0.9,1.4,3,2.5,4.7,2.7c21.5,2.7,43,4.8,64.4,8c42.5,6.2,84.9,12.9,127.3,19.7c15.7,2.5,31.3,5.9,47,8.9c0-131.3,0-262.3,0-393.6
              c-1.2,0.2-2,0.4-2.8,0.5c-54.9,12.3-110.4,22-166.3,28.4c-50.1,5.8-100.3,11.4-150.6,14.9c-55.2,3.9-110.5,6.6-165.7,7.7
              c-58,1.1-116,0.5-173.9-0.9c-45.5-1.1-90.9-3.7-136.2-7.2c-41.8-3.1-83.6-7.4-125.2-12.7c-42.9-5.5-85.6-12.6-128.3-19.6
              c-18.2-3-36.2-7.2-54.5-11c0,131.1,0,262,0,393.2c1.3-0.2,2.5-0.2,3.6-0.5c29.8-5.5,59.5-11.7,89.4-16.2
              c44.9-6.8,90-12.6,135.1-18.6c3.2-0.4,5-1.5,6.7-4.1c11.6-18.6,23.4-37.2,35.2-55.8c0.8-1.2,1.4-2.5,2.5-4.5c-11.9,0-23.1,0-35.2,0
              c26-44.6,51.5-88.3,77.4-132.8C424.6,293.6,450,337.3,476,381.9z" />
            <path d="M1089.3,222.7c-0.1,29.7-24.4,53.7-53.9,53.4c-29.3-0.3-53.3-24.5-53.1-53.8c0.1-29.7,24.2-53.4,54.1-53.2
              C1065.8,169.3,1089.4,193.1,1089.3,222.7z" />
          </svg>
          <span class="h1 ml-3" style="vertical-align:middle">Upload Panoramas</span>
          <p class="h5">
            Drag your file(s) here to begin<br> or click to browse
          </p>
        </div>
      </vue-dropzone>
    </div><!-- bootstrap container -->
    <ShareModal id="share" :publicUrl="currentProject.publicUrl"></ShareModal>
    <DeeplinkModal id="deeplink" ref="deeplinkref" :dlProjectName="currentProject.name" :dlProjectId="currentProject._id">
    </DeeplinkModal>
  </div>
</template>

<script>

import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import draggable from "vuedraggable";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ShareModal from "@/components/ShareModal"
import DeeplinkModal from "@/components/DeeplinkModal"
import { PublishProjectMixin } from "../mixins/publishProjectMixin"
import { HelperFunctionsMixin } from "../mixins/helperFunctionsMixin"
import axios from "axios";

export default {
  name: 'Nodes',
  mixins: [PublishProjectMixin, HelperFunctionsMixin],
  components: {
    vueDropzone: vue2Dropzone,
    draggable,
    ShareModal,
    DeeplinkModal
  },
  data() {
    return {
      editNodeTitle: null,
      editProjectName: false,
      tmpProjectName: '',
      tmpPanoramaTitle: '',
      currentPage: 1,
      perPage: 10,
      autoRefreshInterval: null,
      dropzoneOptions: {
        acceptedFiles: 'image/png, image/jpeg',
        url: '/api/project/_not_set_/uploadnodes',
        maxFilesize: 100,
        maxFiles: 10,
        uploadMultiple: false,
        paramName: "files",
        createImageThumbnails: false,
      },
      disableThumbs: false,
      disableWelcome: false,
      disableNodeTitle: false,
      disableHotspotTitle: false,
      welcomeMessage: "",
      credits: "",
      showPageUpTarget: false,
      showPageDownTarget: false,
      pageUpDownDropped: false,
      dragIndex: -1
    }
  },
  computed: {
    ...mapState('navigation', ['currentProject', 'currentNode', 'currentNodesPage']),
    ...mapState('nodes', ['nodes', 'busyLoading', 'autoReload']),
    ...mapState('user', ['ltiDeeplink', 'jwt', 'token']),
    rows: function () {
      return this.nodes.length;
    },
    nodesForPage: function () {
      return (this.nodes.length > this.perPage ? this.nodes.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage) : this.nodes);
    }
  },
  created: function () {
    if (this.currentProject.custom) {
      this.disableThumbs = this.currentProject.custom.disableThumbs !== undefined && this.currentProject.custom.disableThumbs;
      this.disableWelcome = this.currentProject.custom.disableWelcome !== undefined && this.currentProject.custom.disableWelcome;
      this.disableNodeTitle = this.currentProject.custom.disableNodeTitle !== undefined && this.currentProject.custom.disableNodeTitle;
      this.disableHotspotTitle = this.currentProject.custom.disableHotspotTitle !== undefined && this.currentProject.custom.disableHotspotTitle;
      this.welcomeMessage = this.currentProject.custom.welcomeMessage;
      this.credits = this.currentProject.custom.credits;
    }
    this.setCurrentNode(null);
    this.autoRefreshInterval = setInterval(this.refreshNodeList, 5000);
    this.currentPage = this.currentNodesPage;
  },
  mounted: function () {
    // setup drop zone
    this.$refs.myVueDropzoneNodes.setOption('url', '/api/project/' + this.currentProject._id + '/uploadnodes');
    this.$refs.myVueDropzoneNodes.setOption('headers', { Authorization: "Bearer " + this.token });
    // this.dropzoneOptions.url = '/api/project/' + this.currentProject._id + '/uploadnodes';
    // this.dropzoneOptions.headers.Authorization = "Bearer " + this.token;
  },
  destroyed: function () {
    clearInterval(this.autoRefreshInterval);
  },
  beforeRouteEnter: function (to, from, next) {
    if (from.path == '/projects') {
      next(vm => {
        if (vm.currentProject.name == '(new)') {
          vm.setEditProjectName();
        }
      })
    } else {
      next();
    }
  },
  methods: {
    ...mapActions('navigation', ['setCurrentProject', 'setCurrentNode', 'setCurrentNodesPage']),
    ...mapActions({ alertSuccess: 'alert/success', alertError: 'alert/error' }),
    ...mapActions('projects', ['saveProjectName', 'saveProjectCustomProperties', 'deleteProject']),
    ...mapActions('nodes', ['saveNode', 'deleteNode', 'loadNodes', 'addNodeObject', 'reorderNodes']),
    setEditTitle(node) {
      this.editNodeTitle = node;
      this.tmpPanoramaTitle = node.userdata.title;
      let me = this;
      Vue.nextTick(function () {
        me.$refs.name[0].focus();
      })
    },
    saveTitle(node) {
      if (node.userdata.title != '') {
        let payload = {};
        payload.node = node;
        payload.data = {};
        payload.data.userdata = { 'title': node.userdata.title };
        this.saveNode(payload);
      } else {
        this.alertError('The panorama title must not be empty!');
        this.editNodeTitle.userdata.title = this.tmpPanoramaTitle;
      }
      this.editNodeTitle = null;
    },
    setEditProjectName() {
      this.editProjectName = true;
      this.tmpProjectName = this.currentProject.name;
      let me = this;
      Vue.nextTick(function () {
        me.$refs.projectName.focus();
      })
    },
    async saveProjectNameAndStopEditing() {
      if (this.currentProject.name != '') {
        let response = await this.saveProjectName(this.currentProject);
        if (!response || response.status != 200) {
          this.currentProject.name = this.tmpProjectName;
        }
      } else {
        this.alertError('The project name must not be empty!');
        this.currentProject.name = this.tmpProjectName;
      }
      this.editProjectName = false;
    },
    async saveReturnToLTI() {
      if (await this.publishOk()) {
        this.$refs.deeplinkref.sendDeeplink(this.currentProject.name, `/lti?projectid=${this.currentProject._id}&clone=1`);
      }
    },
    // eslint-disable-next-line
    selectReplaceNodeInput(node) {
      let imagefile = document.querySelector(`#file_${node.nodeId}`);
      imagefile.click();
    },
    async replaceNodeInput(node) {
      let formData = new FormData();
      let imagefile = document.querySelector(`#file_${node.nodeId}`);
      formData.append("files", imagefile.files[0]);
      node.status = 'uploading';
      let res = await axios.post(`/api/node/${node._id}/replaceimage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      await this.loadNodes();
    },
    async moveNodeUpDown(node, up) {
      let nodeIndex = this.nodes.indexOf(node);
      this.rearrangeNodes(nodeIndex, nodeIndex + (up ? -1 : 1));
      await this.reorderNodes(this.currentProject);
    },
    confirmDeleteNode(node) {
      this.$bvModal.msgBoxConfirm('Are you sure?')
        .then(value => {
          if (value) {
            this.deleteNode(node);
          }
        });
    },
    refreshNodeList() {
      if (this._isMounted && this.autoReload) {
        this.loadNodes();
      }
    },
    pageChanged(page) {
      this.setCurrentNodesPage(page);
    },
    dropzoneComplete(response) {
      if (response.status == "success") {
        let data = JSON.parse(response.xhr.response);
        if (data.nodes) {
          let nodes = data.nodes;
          for (let i = 0; i < nodes.length; i++) {
            this.addNodeObject({ node: nodes[i], autoReload: true });
          }
        }
        let dropzone = this.$refs.myVueDropzoneNodes;
        if (dropzone.getQueuedFiles().length == 0 && dropzone.getUploadingFiles().length == 0) {
          dropzone.removeAllFiles();
        }
      }
    },
    openPanoramaFileBrowser() {
      this.$refs.myVueDropzoneNodes.$el.dropzone.hiddenFileInput.click();
    },
    dropzoneError(file, message) {
      let errorMsg = message.error ? message.error : message;
      if (file && file.name) errorMsg += (' (' + file.name + ')');
      this.alertError(errorMsg);
      setTimeout(() => {
        let dropzone = this.$refs.myVueDropzoneNodes;
        if (dropzone.getQueuedFiles().length == 0 && dropzone.getUploadingFiles().length == 0) {
          dropzone.removeAllFiles();
        }
      }, 3000);
    },
    nodesDragStart(event) {
      this.dragIndex = event.oldIndex;
      this.showPageUpTarget = this.currentPage > 1;
      this.showPageDownTarget = this.perPage * this.currentPage < this.nodes.length;
    },
    rearrangeNodes(from, to) {
      if (this.nodes.length > from && this.nodes.length > to) {
        let item = this.nodes.splice(from, 1);
        this.nodes.splice(to, 0, item[0]);
      }
    },
    async nodesTableChanged(event) {
      this.showPageUpTarget = this.showPageDownTarget = false;
      if (this.pageUpDownDropped) {
        this.pageUpDownDropped = false;
        return;
      }
      this.rearrangeNodes(event.oldIndex + (this.perPage * (this.currentPage - 1)), event.newIndex + (this.perPage * (this.currentPage - 1)));
      await this.reorderNodes(this.currentProject);
    },
    async pageUpTargetDropped(event) {
      this.pageUpDownDropped = true;
      this.pageUpTargetHover(event);
      this.rearrangeNodes(this.dragIndex + (this.perPage * (this.currentPage - 1)), (this.perPage * (this.currentPage - 1)) - 1);
      await this.reorderNodes(this.currentProject);
    },
    pageUpTargetHover(event) {
      if (this.showPageUpTarget) {
        let pageUpTarget = document.querySelector('#pageUpTarget');
        this.pageUpDownTargetHoverHandling(event, pageUpTarget);
      }
    },
    async pageDownTargetDropped(event) {
      this.pageUpDownDropped = true;
      this.pageDownTargetHover(event);
      this.rearrangeNodes(this.dragIndex + (this.perPage * (this.currentPage - 1)), this.perPage * this.currentPage);
      await this.reorderNodes(this.currentProject);
    },
    pageDownTargetHover(event) {
      let pageDownTarget = document.querySelector('#pageDownTarget');
      this.pageUpDownTargetHoverHandling(event, pageDownTarget);
    },
    pageUpDownTargetHoverHandling(event, target) {
      switch (event.type) {
        case 'dragenter':
          target.style.background = 'var(--asu-lighter-color)';
          break;
        case 'dragleave':
        case 'dragend':
          if (target === event.target) {
            target.style.background = '';
          }
          break;
        case 'drop':
          target.style.background = '';
          break;
      }
    },
    saveWelcomeMessageAndCredits() {
      if (!this.currentProject.custom) {
        this.currentProject.custom = {};
      }
      this.currentProject.custom.welcomeMessage = this.welcomeMessage;
      this.currentProject.custom.credits = this.credits;
      this.saveProjectCustomProperties(this.currentProject);
    },
    setCustomProperty(prop, value) {
      if (value) {
        if (!this.currentProject.custom) {
          this.currentProject.custom = {};
        }
        this.currentProject.custom[prop] = true;
        this.saveProjectCustomProperties(this.currentProject);
      } else {
        if (this.currentProject.custom && this.currentProject.custom[prop]) {
          delete this.currentProject.custom[prop];
          this.saveProjectCustomProperties(this.currentProject);
        }
      }
    }
    // scrollToUpload() {
    //   let upload = document.getElementById('upload');
    //   upload.scrollIntoView();
    //   let form = upload.querySelector('.dropbox');
    //   form.classList.add('highlight');
    //   setTimeout(() => {
    //     form.classList.remove('highlight');
    //   }, 300);
    // }
  },
  watch: {
    currentProject: function (newVal) {
      this.$refs.myVueDropzoneNodes.setOption('url', '/api/project/' + newVal._id + '/uploadnodes');
      this.$refs.myVueDropzoneNodes.setOption('headers', { Authorization: "Bearer " + this.token });
      if (newVal && newVal.custom) {
        this.disableThumbs = newVal.custom.disableThumbs !== undefined && newVal.custom.disableThumbs;
        this.disableWelcome = newVal.custom.disableWelcome !== undefined && newVal.custom.disableWelcome;
        this.disableNodeTitle = newVal.custom.disableNodeTitle !== undefined && newVal.custom.disableNodeTitle;
        this.disableHotspotTitle = newVal.custom.disableHotspotTitle !== undefined && newVal.custom.disableHotspotTitle;
        this.welcomeMessage = newVal.custom.welcomeMessage;
        this.credits = newVal.custom.credits;
      }
    },
    disableThumbs: function (newVal) {
      this.setCustomProperty('disableThumbs', newVal);
    },
    disableWelcome: function (newVal) {
      this.setCustomProperty('disableWelcome', newVal);
    },
    disableNodeTitle: function (newVal) {
      this.setCustomProperty('disableNodeTitle', newVal);
    },
    disableHotspotTitle: function (newVal) {
      this.setCustomProperty('disableHotspotTitle', newVal);
    }
  }
}
</script>

<style scoped>.dropzone {
  border: none !important;
  background: var(--asu-bg-gray) !important;
  outline: 2px dashed rgb(70, 70, 70);
  outline-offset: -10px;
  min-height: 100px;
  fill: rgb(70, 70, 70);
  color: rgb(70, 70, 70) !important;
}

.dropzone:hover {
  background: var(--asu-lighter-color) !important;
  outline: 2px dashed var(--asu-accent-color);
  color: var(--asu-accent-color) !important;
  fill: var(--asu-accent-color);
}

.dropTarget {
  color: var(--asu-color);
}

h1 {
  margin: 40px 0 0;
}

.button-toggle.active {
  background: var(--info) !important;
}</style>
<style>.dz-message {
  margin: 0 !important;
}</style>
