<template>
  <div class="container-lg bg-light px-0 pt-2">
    <div class="px-2">
      <b-button id="addProjectBtn" class="ml-3 float-left mt-1 mb-2 button-responsive" size="md" variant="outline-secondary" @click="addNewProject" v-b-tooltip.ds200.dh0.hover title="Add new project."><b-icon icon="plus"></b-icon></b-button>
      <b-popover triggers="" variant="info" target="addProjectBtn" placement="bottomright" title="Add Project"><div>To get started, add your first project by clicking this button.</div></b-popover>
      <b-pagination v-if="projects && (projects.length > perPage)" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="projects-table" class="float-right mt-1" @change="pageChanged">
      </b-pagination>
    </div>
    <table v-if="projects && (projects.length > 0)" id="projects-table" class="table table-hover">
      <thead class="thead-light">
      <tr>
        <th style="width:30%">Project</th>
        <th style="width:40%">Name</th>
        <!--
        <th style="width:10%"><span class="d-none d-md-inline">Published</span><b-icon class="d-md-none" icon="globe2"></b-icon></th>
        -->
        <th style="width:20%"></th>
        <th v-if="ltiDeeplink" style="width:10%"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="project in projectsForPage" :key="project._id">
        <td>
          <div v-if="(project.startNode) && (project.startNode.folder) && (project.startNode.folder!='')">
            <span v-on:click="setCurrentProjectAndGotoNodes(project)" style="cursor: pointer" title="Edit Project">
              <span class="d-none d-md-inline" style="color: white; position: absolute; margin-left: 0.4em; margin-top: 0.1em; font-size: 17px; font-weight: bold; letter-spacing: 0.5px; filter: drop-shadow(0px 0px 3px black);"
              :title="'Project Launch: ' + project.stats.launchCnt + '\nProject Complete: ' + project.stats.completeCnt + (project.stats.cloneCnt > 0 ? ('\nClones: ' + project.stats.cloneCnt + '\nClones Launch: ' + project.stats.launchSum + '\nClones Complete: ' + project.stats.completeSum) : '')">{{(project.stats.launchCnt?project.stats.launchCnt:0) + (project.stats.launchSum?project.stats.launchSum:0)}}</span>
              <img class="table-thumbnail" :src="'/s3/preview/' + project.startNode.folder + '/'+ project.startNode.nodeId +'/preview.jpg?_jwt=' + jwt" @keyup.enter="setCurrentProjectAndGotoNodes(project)" tabindex="0" :alt="'Thumbnail Image of Project' + project.name">
              <b-icon variant="light" class="ml-n4 mt-2" style="vertical-align: top; -webkit-filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, 1.0)); filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, 1.0));" icon="pencil-fill"></b-icon>
              <b-icon v-if="project.published" variant="light" class="d-none d-md-inline ml-n3 mb-2" style="vertical-align: bottom; -webkit-filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, 1.0)); filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, 1.0));" icon="eye-fill" title="Public"></b-icon>
            </span>
          </div>
          <div v-else class="mx-auto table-thumbnail" style="background: var(--asu-bg-gray); cursor: pointer" tabindex="0" @keyup.enter="setCurrentProjectAndGotoNodes(project)"><b-icon icon="card-image" class="table-thumbnail-icon" style="min-width: 64px; vertical-align: middle"></b-icon> Click to add panoramas</div>
        </td>
        <td>
          <span v-if="project==editProject"><input @blur="saveProjectNameAndStopEditing(project)" @keyup.enter="saveProjectNameAndStopEditing(project)" ref="name" type="text" v-model="project.name" autofocus></span>
          <span v-else style="cursor: pointer;" @click="setEditing(project)" @keyup.enter="setEditing(project)" tabindex="0">{{project.name}} <b-icon icon="pencil-fill" title="Edit Project Name"/></span>
        </td>
        <td>
          <b-spinner v-if="busyDeletingProject == project || busyPublishingProject == project || busyUnpublishingProject == project"></b-spinner>
          <b-dropdown v-else variant="outline-dark" size="sm" no-flip>
            <template #button-content>
              <span>
                <b-icon icon='three-dots-vertical'></b-icon> Actions
              </span>
            </template>
            <b-dropdown-item-button class="btn-sm" variant="dark" @click="setCurrentProjectAndGotoNodes(project)">
              <b-icon icon="pencil-fill"></b-icon> Edit
            </b-dropdown-item-button>
            <b-dropdown-item variant="dark" class="btn-sm" @click="confirmOpenPreview(project)">
              <b-icon icon="binoculars-fill"></b-icon> Preview
            </b-dropdown-item>
            <b-dropdown-item-button v-if="project.published" variant="dark" class="btn-sm" @click="shareUrl=project.publicUrl; $bvModal.show('share-modal')">
              <b-icon icon="share-fill"></b-icon> Share
            </b-dropdown-item-button>
            <b-dropdown-item-button v-if="project.published" variant="dark" class="btn-sm" @click="unpublishProjectWithFeedback(project)">
              <b-icon icon="eye-slash-fill"></b-icon> Make Private
            </b-dropdown-item-button>
            <b-dropdown-item-button v-else variant="dark" class="btn-sm" @click="shareProject(project)">
              <b-icon icon="share-fill"></b-icon> Share
            </b-dropdown-item-button>
            <b-dropdown-item-button variant="dark" class="btn-sm" @click="cloneProject(project)">
              <b-icon icon="diagram2-fill"></b-icon> Clone
            </b-dropdown-item-button>
            <b-dropdown-item-button variant="dark" class="btn-sm" v-b-modal="'statistics-modal-' + project._id">
              <b-icon icon="graph-up"></b-icon> Statistics
            </b-dropdown-item-button>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item-button variant="warning" class="btn-sm" @click="confirmDeleteProject(project)">
              <b-icon icon="trash-fill"></b-icon> Delete
            </b-dropdown-item-button>
          </b-dropdown>
        </td>
        <td v-if="ltiDeeplink">
          <b-button variant="outline-secondary" class="btn-sm" @click="saveReturnToLTI(project)" v-b-tooltip.ds200.dh0.hover title="Save and return to LMS."><b-icon icon="cloud-upload-fill"></b-icon></b-button>
        </td>
        <b-modal variant="info" title="Project Statistics" centered cancel-disabled :id="'statistics-modal-' + project._id"
          header-bg-variant="light"
          footer-bg-variant="light">
          <table class="w-100" style="text-align: right">
            <thead>
              <th class="w-25"></th>
              <th class="w-25">Original</th>
              <th class="w-25">Clones</th>
              <th class="w-25">Combined</th>
            </thead>
            <tr>
              <td>Launch:</td>
              <td align="right">{{project.stats.launchCnt}}</td>
              <td align="right">{{project.stats.launchSum}}</td>
              <td align="right">{{project.stats.launchCnt + project.stats.launchSum}}</td>
            </tr>
            <tr>
              <td>Complete:</td>
              <td align="right">{{project.stats.completeCnt}}</td>
              <td align="right">{{project.stats.completeSum}}</td>
              <td align="right">{{project.stats.completeCnt + project.stats.completeSum}}</td>
            </tr>
          </table>
        </b-modal>
      </tr>
      </tbody>
    </table>
    <ShareModal id="share" :publicUrl="shareUrl"></ShareModal>
    <DeeplinkModal id="deeplink" ref="deeplinkref" :dlProjectName="dlProjectName" :dlProjectId="dlProjectId"></DeeplinkModal>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import ShareModal from "@/components/ShareModal"
import DeeplinkModal from "@/components/DeeplinkModal"
import { PublishProjectMixin } from "../mixins/publishProjectMixin"
import { HelperFunctionsMixin } from "../mixins/helperFunctionsMixin"

export default {
  name: 'Projects',
  mixins: [PublishProjectMixin, HelperFunctionsMixin],
  components: {
    ShareModal,
    DeeplinkModal
  },
  data(){
    return{
      editProject: null,
      currentPage: 1,
      perPage: 10,
      dlProjectName : '',
      dlProjectId: '',
      tmpProjectName: '',
      shareUrl: '',
    }
  },

  created: function()
  {
    this.$root.$emit('bv::hide::popover');
    this.setCurrentProject(null);
    if (this.loggedIn) {
      this.loadProjects();
    }
    this.currentPage = this.currentProjectsPage;
  },
  computed: {
    ...mapState('navigation', ['currentProject', 'currentProjectsPage']),
    ...mapState('projects', ['projects', 'busyDeletingProject']),
    ...mapState('user', ['loggedIn','ltiDeeplink','jwt']),
    rows: function() {
      return this.projects ? this.projects.length : 0;
    },
    projectsForPage: function() {
      return ((this.projects && this.projects.length > this.perPage) ? this.projects.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage) : this.projects);
    },
  },
  methods: {
    ...mapActions('projects', ['loadProjects', 'addProject', 'saveProjectName', 'deleteProject','cloneProject','publishProject']),
    ...mapActions('navigation', ['setCurrentProject', 'setCurrentProjectsPage']),
    ...mapActions({ alertSuccess: 'alert/success', alertError: 'alert/error' } ),
    setEditing(project) {
      this.editProject=project;
      this.tmpProjectName=project.name;
      let me=this;
      Vue.nextTick(function() {
        me.$refs.name[0].focus();
      });
    },
    async addNewProject() {
      let response = await this.addProject();
      if (response.status == 200) {
        this.currentPage = Math.ceil((this.rows) / this.perPage);
        this.setCurrentProjectAndGotoNodes(this.projects[this.projects.length - 1]);
      }
    },
    saveProjectNameAndStopEditing(project) {
      if (project.name != '') {
        this.saveProjectName(project);
      } else {
        this.alertError('The project name must not be empty!');
        project.name = this.tmpProjectName;
      }
      this.editProject = null;
    },
    confirmDeleteProject(project) {
      this.$bvModal.msgBoxConfirm('Are you sure?')
          .then(value => {
            if (value) {
              this.deleteProject(project);
            }
          });
    },
    setCurrentProjectAndGotoNodes(project) {
      this.setCurrentProject(project);
      this.$router.push({path: '/nodes', query: {projectId: project._id}});
    },
    async saveReturnToLTI(project) {
      if (!project.published) {
        let response = await this.publishProject(project);
        if (response.status != 200) {
          return false;
        }
      }
      this.dlProjectName = project.name;
      this.dlProjectId = project._id;
      this.$refs.deeplinkref.sendDeeplink(project.name,`/lti?projectid=${project._id}&clone=1`);
    },
    pageChanged(page) {
      this.setCurrentProjectsPage(page);
    },
    closeAllPopovers() {
      this.$root.$emit('bv::hide::popover');
    }
  },
  watch: {
    projects: function(newVal) {
      if (newVal.length > 0) {
        this.$root.$emit('bv::hide::popover');
      } else {
        this.$root.$emit('bv::show::popover');
      }
    }
  }
}

</script>

<style scoped>
h1 {
  margin: 40px 0 0;
}
.popover{
    max-width: 100%;
}
</style>
